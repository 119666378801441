import React from 'react';

import BBITable from '../../../helpers/bBITable/BBITable';
import classes from '../../../assets/Global.module.css';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import View from '../../../helpers/slab/View';
import ViewLoad from '../load/Load';
import ViewVendor from '../vendor/Vendor';
import ViewRental from '../../views/rental/Rental';

function Summary({ data }: { data: PayableSummary }) {
  const formattedLineItems = data?.lineItems?.map(lineItem => ({
    ...lineItem,
    rate: lineItem.rate.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    total: lineItem.total.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  const formattedPayments = data?.payments?.map(payment => ({
    ...payment,
    date: DateOnlyFormatter(payment.date),
    amount: payment.amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  let lineItemsColumnConfig = [
    {
      key: '1',
      attribute: 'accountType',
      header: 'Type',
      width: '24ch',
    },
    {
      key: '2',
      attribute: 'note',
      header: 'Note',

      width: '24ch',
    },
    {
      key: '3',
      attribute: 'rate',
      header: 'Rate',
      width: '12ch',
    },
    {
      key: '4',
      attribute: 'quantity',
      header: 'Quantity',
      width: '6ch',
    },
    {
      key: '5',
      attribute: 'total',
      header: 'Total',
      width: 'auto',
    },
  ];

  let paymentsColumnConfig = [
    {
      key: '1',
      attribute: 'cashFlowId',
      header: 'Cash Flow #',

      width: '12ch',
    },
    {
      key: '2',
      attribute: 'date',
      header: 'Date',
      width: '12ch',
    },
    {
      key: '3',
      attribute: 'paymentMethod',
      header: 'Payment Method',

      width: '12ch',
    },
    {
      key: '4',
      attribute: 'note',
      header: 'Note',

      width: '12ch',
    },
    {
      key: '5',
      attribute: 'amount',
      header: 'Amount',

      width: '6ch',
    },
  ];

  const LoadOrRentalInfo = () => {
    const isLoad = data.loadId != null && data.rentalId == null;
    const title = isLoad ? 'Load' : 'Rental';
    if (!data.loadDisplayList || data.loadDisplayList.length === 0) {
      return (
        <>
          <h3>{title}</h3>
          <div>None</div>
        </>
      );
    }

    const viewComponent = isLoad ? <ViewLoad loadId={data.loadId} /> : <ViewRental rentalId={data.rentalId} />;

    return (
      <>
        <h3>{title}</h3>
        {data.loadDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
        <div className={classes.cTA} onClick={() => View(viewComponent)}>
          View {title} Info
        </div>
      </>
    );
  };

  return (
    <div className={classes.viewGrid}>
      <div className={classes.attribute}>
        <LoadOrRentalInfo />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Vendor</h3>
        <span>{data?.vendorName}</span>
        <div
          className={classes.cTA}
          onClick={() =>
            View(
              <ViewVendor
                vendorId={data?.vendorId}
                vendorName={data?.vendorName}
              />,
            )
          }
        >
          View Vendor Info
        </div>
      </div>
      <div className={`${classes.attribute}`}>
        <h3>Payment Status</h3>
        <span>{data?.paymentStatus ? data.paymentStatus : 'None'}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>QuickBooks Bill ID</h3>
        <span>{data?.quickBooksBillId}</span>
      </div>
      <div className={classes.attribute}>
        <h3>Bill Date</h3>
        <span>
          {data?.billDate ? DateOnlyFormatter(data.billDate) : 'None'}
        </span>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Due Date</h3>
        <span>{data?.dueDate ? DateOnlyFormatter(data.dueDate) : 'None'}</span>
      </div>
      <div className={classes.attribute}>
        <h3>Total</h3>
        <span>
          {data?.total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
      <div className={classes.attribute}>
        <h3>Payment</h3>
        <span>
          {data?.payment.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
      <div className={classes.attribute}>
        <h3>Remaining Balance</h3>
        <span>
          {data?.remainingBalance.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Line Items</h3>
        <BBITable
          data={formattedLineItems ?? []}
          columns={lineItemsColumnConfig}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Payments</h3>
        <BBITable
          data={formattedPayments ?? []}
          columns={paymentsColumnConfig}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Note</h3>
        <span>{data?.note ? data.note : 'None'}</span>
      </div>
    </div>
  );
}
export default Summary;
