import React, { useState, useCallback, useId } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { BsTrash } from 'react-icons/bs';

import classes from './FinancialsTable.module.css';
import classesGlobal from '../../assets/Global.module.css';
import OptionList from '../options/OptionList';
import Button from '../../assets/essentials/Button';

/**
 * @component FinancialTable
 * @description Main component for displaying and managing financial data in a table format.
 * @prop {Array} rowData - An array of financial data to be initially displayed in the table.
 * @prop {number} assignmentId - The identifier for the assignment related to the financial data.
 * @prop {string} assignmentType - The type of assignment (e.g., 'asset', 'customer', 'vendor').
 * @prop {Array} accountTypeOptions - An array of options for the account type dropdown.
 * @prop {function} onSubmit - Callback function triggered when the form is submitted.
 * @example

 * function SaveDraftReceivables(e, assignmentId) {
     e.preventDefault();
     const payload = AssembleUpdatedFinancials(e, assignmentId, 'Customer');
    PostBody('Load/SaveDraftFinancial', payload).then(response => {
      if (response) {
          setDisableSaveChanges(false);
        }
      });
    }
 * };
 * <FinancialTable
 *   rowData={customerAssignment.receivables}
 *   assignmentId={customerAssignment.customerAssignmentId}
 *   assignmentType="Customer"
 *   accountTypeOptions={accountTypeOptions}
 *   onSubmit={SaveDraftReceivables}
 * />;
 */

function FinancialTable({
  rowData,
  assignmentId,
  assignmentType,
  accountTypeOptions,
  onSubmit,
  ...rest
}) {
  const [rows, setRows] = useState<FinancialTableRow[]>(
    AssignUniqueIds(rowData),
  );
  const [disableSubmit, setDisableSubmit] = useState<boolean>(
    !rowData?.some((row: { status: string }) => row.status === 'Draft'),
  );
  const formId = useId();

  function AssignUniqueIds(rowData: FinancialTableRow[]) {
    return rowData?.map(row => ({
      ...row,
      uniqueId: row.financialId?.toString() ?? crypto.randomUUID(),
    }));
  }

  const HandleRowChange = useCallback(
    (uniqueId: string, updatedRow: FinancialTableRow) => {
      setRows(prevRows =>
        prevRows.map(row => (row.uniqueId !== uniqueId ? row : updatedRow)),
      );
      setDisableSubmit(false);
    },
    [],
  );

  let financialType: string;
  switch (assignmentType?.toLowerCase()) {
    case 'asset':
      financialType = 'Payroll';
      break;
    case 'customer':
      financialType = 'Receivable';
      break;
    case 'vendor':
      financialType = 'Payable';
      break;
    default:
      financialType = 'FinancialType';
      break;
  }

  const HandleAddRow = () => {
    setRows(prevRows => [
      ...prevRows,
      {
        uniqueId: crypto.randomUUID(),
        financialId: null,
        financialType: financialType,
        status: 'Draft',
        accountTypeId: null,
        rate: null,
        quantity: null,
        total: 0,
        note: '',
      },
    ]);
    setDisableSubmit(false);
  };

  const AddRowButton = () => {
    return (
      <button
        type="button"
        onClick={HandleAddRow}
        className={classes.addButton}
      >
        <IoIosAddCircleOutline />
        Add New {financialType}
      </button>
    );
  };

  const HandleDeleteRow = (uniqueId: string) => {
    setRows(prevItems => prevItems.filter(row => row.uniqueId !== uniqueId));
    setDisableSubmit(false);
  };

  const financialsCurrentSum = rows
    ?.reduce((sum, row) => sum + row.total, 0)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });

  const OnSubmit = (e, assignmentId) => {
    setDisableSubmit(true);
    onSubmit(e, assignmentId);
  };

  return (
    <form
      onSubmit={e => OnSubmit(e, assignmentId)}
      className={`${classes.tableContainer} ${classesGlobal.span3}`}
      id={formId}
      {...rest}
    >
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Type</th>
            <th>Rate</th>
            <th>Quantity</th>
            <th>Total</th>
            <th>Note</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((data: FinancialTableRow, index: number) => (
            <Row
              index={index}
              uniqueId={data.uniqueId}
              rowData={data}
              accountTypeOptions={accountTypeOptions}
              onChange={HandleRowChange}
              onDelete={HandleDeleteRow}
            />
          ))}
        </tbody>
      </table>
      <div>
        <br />
        <AddRowButton />
        <b>Total: {financialsCurrentSum} </b>
      </div>
      <Button
        type="submit"
        variant="good"
        form={formId}
        disabled={disableSubmit}
      >
        Save Changes
      </Button>
    </form>
  );
}

/**
 * @component Row
 * @description Renders a single row in the financial table.
 * @prop {string} uniqueId - A unique ID that is either an existing FinancialID or a unique string used to identify individual rows if a FinancialID isn't available ex: a draft
 * @prop {number} index - The index of the row in the table, used to give inputs unique names to prevent formData removing duplicates.
 * @prop {Object} rowData - Financial details for the specific row.
 * @prop {Array} accountTypeOptions - An array of options for the account type dropdown.
 * @prop {function} onChange - Callback function triggered on input change.
 * @prop {function} onDelete - Callback function triggered when the delete button is clicked.
 */

const Row = ({
  uniqueId,
  index,
  rowData,
  accountTypeOptions,
  onChange,
  onDelete,
}: {
  uniqueId: string;
  index: number;
  rowData: FinancialTableRow;
  accountTypeOptions: AccountTypeOption[];
  onChange: Function;
  onDelete: Function;
}) => {
  const HandleChange = e => {
    const { name, value } = e.target;
    const attributeName = name.split('-')[0];
    let updatedRow = { ...rowData, status: 'Draft', [attributeName]: value };
    if (attributeName === 'rate' || attributeName === 'quantity') {
      updatedRow = {
        ...updatedRow,
        // +0.000000000001 to account for Javascript precision floating point oddities
        total: parseFloat(
          (updatedRow.rate * updatedRow.quantity + 0.000000000001).toFixed(2),
        ),
      };
    }
    onChange(uniqueId, updatedRow);
  };

  const HandleDelete = () => {
    onDelete(uniqueId);
  };

  return (
    <tr
      key={rowData.uniqueId}
      className={rowData.status === 'Draft' ? classes.draft : ''}
    >
      <td>
        {rowData.status}
        <input
          type="hidden"
          name={`status-${index}`}
          id="status"
          defaultValue={rowData.status}
        />
        <input
          type="hidden"
          name={`financialId-${index}`}
          id="financialId"
          defaultValue={rowData.financialId}
        />
        <input
          type="hidden"
          name={`financialType-${index}`}
          id="financialType"
          defaultValue={rowData.financialType}
        />
      </td>
      <td>
        <select
          onChange={HandleChange}
          defaultValue={rowData.accountTypeId ?? ''}
          name={`accountTypeId-${index}`}
          id="accountTypeId"
          required
        >
          <option value={''} hidden>
            -- Required --
          </option>
          <OptionList
            optionData={accountTypeOptions}
            attributeID="accountTypeId"
            attributeName="type"
            attributeGroup="group"
          />
        </select>
      </td>
      <td>
        <input
          onChange={HandleChange}
          defaultValue={rowData.rate}
          type="number"
          name={`rate-${index}`}
          id="rate"
          step={0.01}
          required
        />
      </td>
      <td>
        <input
          onChange={HandleChange}
          defaultValue={rowData.quantity}
          type="number"
          name={`quantity-${index}`}
          id="quantity"
          min={0.01}
          step={0.01}
          required
        />
      </td>
      <td>
        {rowData.total?.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}
      </td>
      <td>
        <input
          onChange={HandleChange}
          defaultValue={rowData.note || ''}
          type="text"
          name={`note-${index}`}
          id="note"
        />
      </td>
      <td>
        <button
          onClick={HandleDelete}
          className={classes.deleteButton}
          type="button"
        >
          <BsTrash className={classesGlobal.clickable} />
        </button>
      </td>
    </tr>
  );
};

export default FinancialTable;
