import React, { useState, useEffect } from 'react';
import Slab from '../../../../helpers/slab/Slab';
import Get from '../../../../api/internal/Get';
import PayrollPaymentDetails from './Summary';
import Admin from './Admin';

interface PayrollPaymentDataObject {
  Summary?: APPaymentDetail;
}

function PayrollPaymentDetail({
  cashFlowId,
  startPage,
}: {
  cashFlowId: number;
  startPage?: String;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [dataObject] = useState<PayrollPaymentDataObject>({});

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  const HandlePageChange = params => {
    if (!dataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          dataObject[params] = response.data;
          AssignPages();
          setCurrentPage(params);
        }
      });
    } else {
      setCurrentPage(params);
    }
  };

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <PayrollPaymentDetails
            data={dataObject.Summary}
            cashFlowId={cashFlowId}
          />
        ),
        callerPath: `/Accounting/GetPayrollPaymentDetails/${cashFlowId}`,
      },
      Admin: {
        content: <Admin cashFlowId={cashFlowId} />,
      },
    };
  }

  return (
    <Slab
      title={'Payroll Payment Detail'}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={dataObject}
    />
  );
}

export default PayrollPaymentDetail;