import React, { useState } from 'react';
import ClassesError from '../../../../helpers/error/Error.module.css';
import Button from '../../../../assets/essentials/Button';

interface ReversePaymentModalProps {
  closeModal: Function;
  handleReverse: Function;
}
const ReversePaymentModal = ({
  closeModal,
  handleReverse,
}: ReversePaymentModalProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  return (
    <div className={ClassesError.errorContainer}>
      <div className={ClassesError.overlay} onClick={() => closeModal()} />
      <div className={ClassesError.errorHolder}>
        <div className={ClassesError.yesNoModal}>
          <h3>Are you sure you want to reverse this payment?</h3>
          <div className={ClassesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => {
                setIsButtonDisabled(true);
                handleReverse();
              }}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => closeModal()}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReversePaymentModal;
