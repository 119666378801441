import React, { useEffect, useState } from 'react';

import Get from '../../../api/internal/Get';
import Payments from '../accounting/Payments';
import Slab from '../../../helpers/slab/Slab';
import Summary from './Summary';
import Details from './Details';
import LoadFiles from '../../creates/file/Files';
import RentalFiles from '../../creates/file/Files';
import Audit from '../generic/Audit';
import EditLineItems from '../accounting/EditLineItems';
import CreateInvoice from './CreateInvoice';
import Admin from './Admin';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';

function Receivable({
  customerAssignmentId,
  loadId,
  rentalId,
  startPage,
}: {
  customerAssignmentId: number;
  loadId?: number;
  rentalId?: number;
  startPage?: String;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [dataObject] = useState<ReceivableDataObject>({
    Summary: null,
    Details: null,
    Payments: null,
    'Line Items': null,
    Invoice: null,
    Files: null,
    Audit: null,
    Admin: null,
  });
  const filesContent = loadId ? (
    <LoadFiles
      data={dataObject.Files}
      categoryId={loadId}
      fileCategory={'load'}
      RefreshPage={RefreshPage}
    />
  ) : (
    <RentalFiles
      data={dataObject.Files}
      categoryId={rentalId}
      fileCategory={'rental'}
      RefreshPage={RefreshPage}
    />
  );
  const filesCallerPath = loadId
    ? `/File/GetAllFiles/load-${loadId}`
    : `/File/GetAllFiles/rental-${rentalId}`;

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  const HandlePageChange = params => {
    if (!dataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          dataObject[params] = response.data;
          AssignPages();
          setCurrentPage(params);
        }
      });
    } else {
      setCurrentPage(params);
    }
  };

  function RefreshPage(currentPage, additionalPages) {
    additionalPages?.forEach(pageName => {
      delete dataObject[pageName];
    });
    if (currentPage) {
      setCurrentPage(null);
      delete dataObject[currentPage];
      HandlePageChange(currentPage);
    }
  }

  let pages = {};
  AssignPages();

  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <Summary
            data={dataObject.Summary}
            HandlePageChange={HandlePageChange}
          />
        ),
        callerPath: `/Accounting/GetReceivableSummary/${customerAssignmentId}`,
      },
      Files: {
        content: filesContent,
        callerPath: filesCallerPath,
      },
      Details: {
        content: (
          <Details
            data={dataObject.Details}
            customerAssignmentId={customerAssignmentId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetReceivableDetails/${customerAssignmentId}`,
      },
      'Line Items': {
        content: (
          <EditLineItems
            lineItems={dataObject['Line Items']?.lineItems}
            assignmentId={customerAssignmentId}
            assignmentType={'Customer'}
            accountTypeOptions={dataObject['Line Items']?.accountTypeOptions}
            RefreshPage={() => RefreshPage('Line Items', ['Audit', 'Summary'])}
          />
        ),
        callerPath: `/Accounting/GetReceivableLineItems/${customerAssignmentId}`,
      },
      Invoice: {
        content: (
          <CreateInvoice
            data={dataObject.Invoice}
            customerAssignmentId={customerAssignmentId}
            isLoadInvoice={loadId != null}
            HandlePageChange={HandlePageChange}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetCustomerInvoiceDetails/${customerAssignmentId}`,
      },
      Payments: {
        content: (
          <Payments
            relationId={customerAssignmentId}
            relationType={'Customer'}
            data={dataObject.Payments}
            HandlePageChange={HandlePageChange}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetPaymentMethodList/`,
      },
      Audit: {
        content: <Audit data={dataObject.Audit} />,
        callerPath: `/Accounting/GetCustomerAssignmentAudit/${customerAssignmentId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            data={dataObject.Admin}
            customerAssignmentId={customerAssignmentId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetReceivableAdmin/${customerAssignmentId}`,
      };
  }

  const slabTitle = `Receivable Assignment: ${
    loadId ? `Load #${loadId}` : rentalId ? `Rental #${rentalId}` : ''
  }`;

  return (
    <Slab
      title={slabTitle}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={dataObject}
    />
  );
}
export default Receivable;
