import React, { useEffect } from 'react';

import classes from './Modal.module.css';
import Button from '../../assets/essentials/Button';
import CloseModal from './CloseModal';

function Modal({ children }: { children: JSX.Element }) {
  useEffect(() => {
    const handleEsc = event => {
      let escapeKeyCode = 27;
      if (event.keyCode === escapeKeyCode) {
        CloseModal();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <div className={classes.modal}>
      <div>{children}</div>
    </div>
  );
}

export default Modal;
