import classes from './HeaderBanner.module.css';
import BBILogo from '../assets/Metal BBI Logo.png';
import { Link } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';
import AdvancedSearch from '../helpers/search/AdvancedSearch';
import { RiMailSettingsLine } from 'react-icons/ri';
import Create from '../helpers/modal/CreateModal';
import Modal from '../helpers/modal/Modal';
import EmailTemplate from './EmailTemplate';

function HeaderBanner() {
  const handleEmail = () => {
    Create(
      <Modal>
        <EmailTemplate />
      </Modal>,
    );
  };
  return (
    <div className={classes.headerBanner}>
      <Link to="/">
        <img className={classes.logo} alt="BBI Logo" src={BBILogo} />
        <div className={classes.title}>BBI Transportation</div>
      </Link>
      <div className={classes.filterHolder}>
        <AdvancedSearch />
      </div>
      <div className={classes.profileHolder}>
        <RiMailSettingsLine onClick={handleEmail} />
        <ProfileMenu />
      </div>
    </div>
  );
}

export default HeaderBanner;
