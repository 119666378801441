import React from 'react';
import Get from '../../api/internal/Get';

// This component is used to test the QuickBooks API. It sends a request to the server to get company information.
// The company info can be used to test which company/environment is connected. This is useful for debugging and testing purposes.

const QuickBooksTest = () => {
  function GetCompanyInfo() {
    Get('/Accounting/GetCompanyInfo').then(response => {
      if (response) {
        console.log(response.data);
      }
    });
  }

  return <button onClick={GetCompanyInfo}>Get Company Info</button>;
};

export default QuickBooksTest;
