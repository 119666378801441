import React, { useState } from 'react';
import Input from '../assets/essentials/Input';
import TextArea from '../assets/essentials/TextArea';
import classes from './EmailTemplate.module.css';
import Button, { ButtonStatus } from '../assets/essentials/Button';
import PostForm from '../api/internal/PostForm';
import useTimeout from '../hooks/useTimeout';
import CloseModal from '../helpers/modal/CloseModal';

const EmailTemplate = () => {
  const [status, setStatus] = useState<ButtonStatus>('idle');

  //display success but reset after 10 secs
  useTimeout(() => {
    if (status === 'success' || status === 'failure') {
      setStatus('idle');
    }
  }, 10000);

  const handleSubmit = e => {
    e.preventDefault();
    setStatus('pending');
    const formData = new FormData(e.target);
    PostForm(`/Email/SendSupportEmail`, formData).then(() => {
      setStatus('success');
    });
  };

  //this is hardcoded on backend also
  const SUPPORT_EMAIL_ADDRESS = 'TransportationSupport@bbilogistics.com';

  return (
    <section className={classes.container}>
      <h3 className={classes.title}>Email Transportation Support</h3>
      <form className={classes.form} onSubmit={e => handleSubmit(e)}>
        <label htmlFor="to">To</label>
        <Input
          disabled
          defaultValue={SUPPORT_EMAIL_ADDRESS}
          name="to"
          id="to"
        />
        <label htmlFor="subject">Subject</label>
        <Input id="subject" name="subject" required />
        <label htmlFor="body">Body</label>
        <TextArea id="body" name="body" required />
        <div className={classes.buttonContainer}>
          <Button type="submit" status={status}>
            Send
          </Button>
          <Button variant="bad" onClick={() => CloseModal()}>
            Close
          </Button>
        </div>
      </form>
    </section>
  );
};

export default EmailTemplate;
