import React, { useEffect, useRef, useState } from 'react';
import Get from '../../../api/internal/Get';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import classesGlobal from '../../../assets/Global.module.css';
import classesApplyPayment from '../receivables/ApplyPayment/ApplyReceivablePayments.module.css';
import Header from '../../../layouts/Header';
import Filter from '../../../helpers/filter/Filter';
import FilterData from '../../../helpers/filter/FilterData';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Button from '../../../assets/essentials/Button';
import PostBody from '../../../api/internal/PostBody';
import View from '../../../helpers/slab/View';
import ViewLoad from '../../../features/views/load/Load';
import ViewRental from '../../../features/views/rental/Rental';
import { GetCurrentDate, GetSixMonthsAgoDate } from '../helperFunctions';
import APAppliedPayments from '../../../features/views/payable/AppliedPayments/APAppliedPayments';
import { useOutletContext } from 'react-router-dom';

type ApplyPaymentStatus = 'success' | 'pending' | 'idle';

function ApplyPayablePayments({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [searchOptionsList, setSearchOptionsList] = useState([]);
  const [paymentOptionsList, setPaymentOptionsList] = useState<
    PaymentMethodOption[]
  >([]);
  const [vendorAssignments, setVendorAssignments] = useState<
    ApplyPaymentVendorAssignment[]
  >([]);
  const [appliedPaymentStatus, setAppliedPaymentStatus] =
    useState<ApplyPaymentStatus>('idle');
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const inputValues = useRef({
    vendorId: null,
    paymentMethodId: '',
    reference: '',
    note: '',
    paymentDate: GetCurrentDate(),
  });
  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Vendor/GetVendorList`).then(response => {
      if (response) {
        setSearchOptionsList(response.data);
      }
    });
    Get(`/Accounting/GetPaymentMethodList`).then(response => {
      if (response) {
        setPaymentOptionsList(response.data);
      }
    });
  }, [toggleBoardRefresh]);

  function GetVendorAssignments(vendorId) {
    if (vendorId)
      Get(`/Accounting/GetVendorAssignmentsForApplyPayment/${vendorId}`).then(
        response => {
          if (response) {
            setVendorAssignments(response.data);
            inputValues.current.vendorId = vendorId;
            setPaymentAmount(0.0);
          }
        },
      );
  }

  const appliedAmount = vendorAssignments.reduce(
    (sum, row) => sum + Number(row.applyToBalance ?? 0),
    0,
  );

  //unary operator because i need a number, not a string
  const balance = +(paymentAmount - appliedAmount).toFixed(2);

  function FormatVendorAssignments(
    vendorAssignments: ApplyPaymentVendorAssignment[],
  ) {
    return vendorAssignments?.map(assignment => ({
      ...assignment,
      attribute: assignment.loadId
        ? assignment.loadId
        : assignment.rentalId && 'Rental: ' + assignment.rentalId,
      billDate: DateOnlyFormatter(assignment.billDate),
      loadId: assignment.loadId,
      dueDate: assignment.dueDate
        ? DateOnlyFormatter(assignment.dueDate)
        : 'N/A',
      totalPayable: assignment.totalPayable.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      remainingPayableBalance:
        assignment.remainingPayableBalance.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
      applyToBalance:
        typeof assignment?.applyToBalance === 'number'
          ? assignment.applyToBalance.toFixed(2)
          : null,
    }));
  }

  function ApplyPaymentsToSelectedVendorAssignments(paymentAmount: number) {
    const updatedVendorAssignments = [...vendorAssignments];
    let remainingPayment = paymentAmount;

    for (let i = 0; i < updatedVendorAssignments.length; i++) {
      const vendorAssignment = updatedVendorAssignments[i];
      vendorAssignment.applyToBalance = null;
      if (vendorAssignment.isSelected) {
        if (remainingPayment >= vendorAssignment.remainingPayableBalance) {
          remainingPayment -= vendorAssignment.remainingPayableBalance;
          vendorAssignment.applyToBalance =
            vendorAssignment.remainingPayableBalance;
        } else {
          vendorAssignment.applyToBalance = remainingPayment;
          remainingPayment = 0;
        }
      }
    }
    setVendorAssignments(updatedVendorAssignments);
  }

  const VendorStats = () => {
    const totalBalance = vendorAssignments
      .reduce((sum, row) => sum + row.remainingPayableBalance, 0)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    return (
      <div>
        <h3>Bill Count: {vendorAssignments.length}</h3>
        <h3>Total Balance: {totalBalance}</h3>
      </div>
    );
  };

  const VendorAssignmentTable = () => {
    const isBalanceApplied = applyToBalance =>
      !isNaN(applyToBalance) && applyToBalance;

    const HandleInputChange = (index: number, value: string) => {
      setVendorAssignments(prevVendorAssignments => {
        return prevVendorAssignments.map((assignment, i) => {
          if (i === index) {
            return {
              ...assignment,
              isSelected: isBalanceApplied(value),
              applyToBalance: Number(value),
            };
          }
          return assignment;
        });
      });
    };

    function HandleRowSelect(index: number, checked: boolean) {
      if (checked === true) {
        setVendorAssignments(prevAssignments => {
          return prevAssignments.map((assignment, i) => {
            if (i === index) {
              return {
                ...assignment,
                isSelected: true,
                applyToBalance: assignment.remainingPayableBalance,
              };
            }
            return assignment;
          });
        });
      } else {
        setVendorAssignments(prevAssignments => {
          return prevAssignments.map((assignment, i) => {
            if (i === index) {
              return {
                ...assignment,
                isSelected: false,
                applyToBalance: null,
              };
            }
            return assignment;
          });
        });
      }
    }

    const HandleAttributeOnClick = row => {
      if (row.loadId) {
        return View(<ViewLoad loadId={row.loadId} />);
      } else if (row.rentalId) {
        return View(<ViewRental rentalId={row.rentalId} />);
      }
    };

    const formattedVendorAssignments =
      FormatVendorAssignments(vendorAssignments);
    const filteredVendorAssignments = FilterData(
      formattedVendorAssignments,
      filterValue,
    );

    const isEveryAssignmentSelected =
      filteredVendorAssignments.every(assignment => assignment.isSelected) &&
      filteredVendorAssignments.length > 0;

    const ToggleSelectAll = () => {
      const updatedVendorAssignments = vendorAssignments.map(item => {
        const vendorAssignment = {
          ...item,
          isSelected: !isEveryAssignmentSelected,
        };
        if (isEveryAssignmentSelected) {
          vendorAssignment.applyToBalance = null;
        } else if (!isEveryAssignmentSelected) {
          vendorAssignment.applyToBalance =
            vendorAssignment.remainingPayableBalance;
        }
        return vendorAssignment;
      });
      setVendorAssignments(updatedVendorAssignments);
    };

    return (
      <div className={classesApplyPayment.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={ToggleSelectAll}
                  checked={isEveryAssignmentSelected}
                />
              </th>
              <th>Load #</th>
              <th>Reference</th>
              <th>QuickBooks ID</th>
              <th>Bill Date</th>
              <th>Due Date</th>
              <th>Bill Total</th>
              <th>Balance</th>
              <th>Apply To Balance</th>
            </tr>
          </thead>
          <tbody>
            {filteredVendorAssignments?.map((row, index) => (
              <tr key={row.vendorAssignmentId}>
                <td>
                  <input
                    type="checkbox"
                    name="selected"
                    checked={row.isSelected}
                    onChange={e => HandleRowSelect(index, e.target.checked)}
                  />
                </td>
                <td
                  onClick={() => HandleAttributeOnClick(row)}
                  className={classesGlobal.clickable}
                >
                  {row.attribute}
                </td>
                <td
                  onClick={() => HandleAttributeOnClick(row)}
                  className={row.reference ? classesGlobal.clickable : ''}
                >
                  {row.reference}
                </td>
                <td>{row.quickBooksInvoiceId}</td>
                <td>{row.billDate}</td>
                <td>{row.dueDate}</td>
                <td>{row.totalPayable}</td>
                <td>{row.remainingPayableBalance}</td>
                <td>
                  <input
                    placeholder="0.00"
                    min={0}
                    max={row.remainingPayableBalance}
                    type="number"
                    defaultValue={row.applyToBalance}
                    onBlur={e => {
                      HandleInputChange(index, e.target.value);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const HandleSubmitApplyPayments = e => {
    e.preventDefault();
    setAppliedPaymentStatus('pending');
    const selectedVendorAssignments = vendorAssignments
      .filter(ca => ca.applyToBalance)
      .map(ca => ({
        vendorAssignmentId: ca.vendorAssignmentId,
        applyToBalanceAmount: ca.applyToBalance.toFixed(2),
      }));
    const applyPaymentParam = {
      vendorId: inputValues.current.vendorId,
      paymentMethodId: inputValues.current.paymentMethodId,
      reference: inputValues.current.reference,
      note: inputValues.current.note,
      paymentDate: inputValues.current.paymentDate,
      paymentAmount: paymentAmount,
      vendorAssignments: selectedVendorAssignments,
    };
    PostBody('Accounting/ApplyPaymentVendorAssignment', applyPaymentParam).then(
      response => {
        if (response) {
          let newCashFlowId = response.data;
          GetVendorAssignments(applyPaymentParam.vendorId);
          HandleAppliedPaymentSuccessfully(newCashFlowId);
        }
      },
    );
  };

  const HandleAppliedPaymentSuccessfully = (newCashFlowId: number) => {
    inputValues.current.paymentMethodId = '';
    inputValues.current.reference = '';
    inputValues.current.note = '';
    inputValues.current.paymentDate = GetCurrentDate();
    setAppliedPaymentStatus('success');
    View(<APAppliedPayments cashFlowId={newCashFlowId} />);
  };

  const SubmitPaymentsButton = () => {
    const isDisabled = appliedPaymentStatus === 'pending' || balance !== 0;
    return (
      <div>
        <Button disabled={isDisabled} type="submit" variant="good">
          {balance !== 0 ? 'Balance must be zero.' : 'Submit Payments'}
        </Button>
      </div>
    );
  };

  //using form for input validation
  const ApplyPaymentForm = () => {
    return (
      <form
        className={classesApplyPayment.applyPaymentSection}
        onSubmit={e => HandleSubmitApplyPayments(e)}
      >
        <div className={classesGlobal.attribute}>
          <label htmlFor="paymentMethodId">
            Payment Method <span className={classesGlobal.required}>*</span>
          </label>
          <Select
            required
            defaultValue={inputValues.current.paymentMethodId}
            name="paymentMethodId"
            id="paymentMethodId"
            onChange={e => {
              inputValues.current.paymentMethodId = e.target.value;
            }}
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={paymentOptionsList}
              attributeID="paymentMethodId"
              attributeName="method"
              attributeGroup=""
            />
          </Select>
          <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
            <label htmlFor="reference">
              Reference/Check Number{' '}
              <span className={classesGlobal.required}>*</span>
            </label>
            <Input
              type="text"
              id="reference"
              defaultValue={inputValues.current.reference}
              onChange={e => {
                inputValues.current.reference = e.target.value;
              }}
              required
            />
          </div>
          <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
            <label htmlFor="note">Note</label>
            <TextArea
              id="note"
              name="note"
              defaultValue={inputValues.current.note}
              onChange={e => {
                inputValues.current.note = e.target.value;
              }}
            />
          </div>
          <div>
            <SubmitPaymentsButton />
          </div>
        </div>
        <div className={`${classesGlobal.attribute}`}>
          <label htmlFor="paymentDate">
            Payment Date <span className={classesGlobal.required}>*</span>
          </label>
          <Input
            type="date"
            id="paymentDate"
            min={GetSixMonthsAgoDate()}
            max={GetCurrentDate()}
            defaultValue={inputValues.current.paymentDate}
            onChange={e => {
              inputValues.current.paymentDate = e.target.value;
            }}
            required
          />
          <label htmlFor="paymentAmount">
            Payment Amount <span className={classesGlobal.required}>*</span>
          </label>
          <Input
            type="number"
            id="paymentAmount"
            step={0.01}
            min={0.01}
            defaultValue={paymentAmount}
            onBlur={e => {
              setPaymentAmount(Number(e.target.value));
            }}
            required
          />
          <div>
            <button
              className={classesApplyPayment.applyPaymentButton}
              onClick={() =>
                ApplyPaymentsToSelectedVendorAssignments(paymentAmount)
              }
            >
              Apply Payment to Selected
            </button>
          </div>
          <label htmlFor="balance">Balance</label>
          <Input
            type="string"
            id="balance"
            value={balance?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            min={0}
            readOnly
          />
          <label htmlFor="appliedAmount">Applied Amount</label>
          <Input
            type="string"
            id="appliedAmount"
            value={appliedAmount?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            readOnly
          />
        </div>
      </form>
    );
  };

  return (
    <>
      <Header>
        <Select
          defaultValue=""
          onChange={e => {
            GetVendorAssignments(e.target.value);
            setAppliedPaymentStatus('idle');
          }}
        >
          <option disabled value="">
            Select Vendor
          </option>
          <OptionList
            optionData={searchOptionsList}
            attributeID="vendorId"
            attributeName="vendorName"
            attributeGroup=""
          />
        </Select>
      </Header>
      <ApplyPaymentForm />
      <Header>
        <h3>Payment Details</h3>
        <VendorStats />
        <br/>
      </Header>
      <VendorAssignmentTable />
    </>
  );
}

export default ApplyPayablePayments;
