import React, { useState } from 'react';
import Form from '../../../../assets/essentials/Form';
import Input from '../../../../assets/essentials/Input';
import classes from '../../../../assets/Global.module.css';
import Button from '../../../../assets/essentials/Button';
import PutForm from '../../../../api/internal/PutForm';
import PostBody from '../../../../api/internal/PostBody';
import Close from '../../../../helpers/slab/Close';
import ReversePaymentModal from '../../receivable/AppliedPayments/ReversePaymentModal';

const Admin = ({
  cashFlowId,
  data,
  RefreshPage,
}: {
  cashFlowId;
  data: AppliedPaymentsAdmin;
  RefreshPage: Function;
}) => {
  const [isReversePaymentModalOpen, setIsReversePaymentModalOpen] =
    useState(false);

  function UpdateQuickBooksPaymentId(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = { cashFlowId };
    PutForm(`/Accounting/UpdateAppliedPaymentAdmin`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Summary']);
        }
      },
    );
  }

  function ReversePayablePayment() {
    const params = {
      cashFlowId: cashFlowId,
    };
    PostBody(`/Accounting/ReversePayablePayment`, params).then(response => {
      if (response) {
        Close();
      }
    });
  }

  return (
    <>
      <Form
        onSubmit={e => {
          UpdateQuickBooksPaymentId(e);
        }}
      >
        <div className={`${classes.span3} ${classes.attribute}`}>
          <label htmlFor="quickBooksPaymentId">QuickBooks Invoice ID</label>
          <Input
            defaultValue={data.quickBooksPaymentId}
            name="quickBooksPaymentId"
            id="quickBooksPaymentId"
          />
        </div>
        <div>
          <Button disabled variant="good" type="submit">
            Save Changes
          </Button>
        </div>
      </Form>
      <div className={classes.marginTop05Rem}>
        {isReversePaymentModalOpen && (
          <ReversePaymentModal
            closeModal={() => setIsReversePaymentModalOpen(false)}
            handleReverse={() => ReversePayablePayment()}
          />
        )}
        <Button
          variant="good"
          onClick={() => setIsReversePaymentModalOpen(true)}
        >
          Reverse Payable Payment
        </Button>
      </div>
    </>
  );
};

export default Admin;
