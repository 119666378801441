import React from 'react';
import classes from '../../../assets/Global.module.css';
import OptionList from '../../../helpers/options/OptionList';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../customer/Customer';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';

function Details({
  rentalDetails,
  rentalSummaryOptions,
  rentalId,
  RefreshPage,
}: {
  rentalDetails: RentalDetails;
  rentalId: number;
  rentalSummaryOptions: RentalSummaryOptions;
  RefreshPage: Function;
}) {
  return (
    <>
      <div className={classes.attribute}>
        <label>
          Customer{' - '}
          <span
            className={`${classes.cTA}`}
            onClick={() =>
              View(
                <ViewCustomer
                  customerId={rentalDetails.customerId}
                  customerName={rentalDetails.customerName}
                />,
              )
            }
          >
            Manage Customer Info
          </span>
        </label>
        <Input readOnly type="text" defaultValue={rentalDetails.customerName} />
      </div>

      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Reference</label>
        <Input
          type="text"
          defaultValue={rentalDetails?.reference}
          name="reference"
        />
      </div>
      <div className={classes.attribute}>
        <label>Status</label>
        <Select
          defaultValue={rentalDetails?.rentalStatusId}
          name="rentalStatusId"
        >
          <OptionList
            optionData={rentalSummaryOptions.rentalStatusOptions}
            attributeID="rentalStatusId"
            attributeName="status"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="trailerNumber">Trailer #</label>        
        <Input id="trailerNumber" type="text" defaultValue={rentalDetails.trailerNumber} name="trailerNumber" />
      </div>
      <div className={`${classes.attribute}`}>
        <label>Start Date</label>
        <Input
          type="date"
          defaultValue={rentalDetails?.startDate}
          name="startDate"
        />
      </div>

      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>End Date</label>
        <Input
          type="date"
          defaultValue={rentalDetails?.endDate}
          name="endDate"
        />
      </div>

      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Rental Instructions</label>
        <TextArea
          rows={3}
          defaultValue={rentalDetails?.instructions}
          name="instructions"
        ></TextArea>
      </div>
    </>
  );
}
export default Details;
