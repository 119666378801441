import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Button from '../../../assets/essentials/Button';
import PutForm from '../../../api/internal/PutForm';
import Form from '../../../assets/essentials/Form';
import Phone from '../../../helpers/inputs/Phone';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';

function Details({
  data,
  vendorId,
  RefreshPage,
}: {
  data: VendorDetails;
  vendorId: Number;
  RefreshPage: Function;
}) {
  const isUserAdmin = useRoleAuthorization(ADMIN_AUTHORIZED_ROLES);
  function UpdateVendorDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      vendorId: vendorId,
    };
    PutForm(`/Vendor/UpdateVendorDetails`, formData, appendix).then(() =>
      RefreshPage('Details', ['Audit', 'Summary']),
    );
  }

  return (
    <Form onSubmit={e => UpdateVendorDetails(e)}>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          Vendor Name <span className={classes.required}>*</span>
        </label>
        <Input
          required
          type="text"
          key={data?.vendorName}
          defaultValue={data?.vendorName}
          name="vendorName"
          disabled={!isUserAdmin}
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Address Line One <span className={classes.required}>*</span>
        </label>
        <Input
          required
          type="text"
          key={data?.addressLineOne}
          defaultValue={data?.addressLineOne}
          name="addressLineOne"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Address Line Two</label>
        <Input
          type="text"
          key={data?.addressLineTwo}
          defaultValue={data?.addressLineTwo}
          name="addressLineTwo"
        />
      </div>
      <div className={classes.attribute}>
        <label>
          City <span className={classes.required}>*</span>
        </label>
        <Input
          required
          key={data?.city}
          defaultValue={data?.city}
          type="text"
          name="city"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>State/Province</label>
        <Select
          required
          key={data?.state}
          defaultValue={data?.state}
          name="state"
        >
          <StatesAndProvinces />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>
          Zip <span className={classes.required}>*</span>
        </label>
        <Input
          required
          key={data?.zip}
          defaultValue={data?.zip}
          type="text"
          name="zip"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Country</label>
        <Select
          required
          key={data?.country}
          defaultValue={data?.country}
          name="country"
        >
          <option key={0} value="US">
            US
          </option>
          <option key={1} value="CA">
            Canada
          </option>
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>
          Phone <span className={classes.required}>*</span>
        </label>
        <Phone
          defaultValue={data?.phone}
          name="phone"
          onChange={null}
          expectsEventObject={false}
          required={true}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Ext</label>
        <Input defaultValue={data?.phoneExt} type="text" name="phoneExt" />
      </div>
      <div className={classes.attribute}>
        <label>Website</label>
        <Input defaultValue={data?.website} type="text" name="website" />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Vendor Type</label>
        <Select name="vendorTypeId" defaultValue={data?.vendorTypeId}>
          <OptionList
            optionData={data?.vendorTypeOptions}
            attributeID="vendorTypeId"
            attributeName="type"
            attributeGroup="group"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Note</label>
        <TextArea defaultValue={data?.note} type="text" name="note" />
      </div>
      <div>
        <Button type="submit" variant="good" disabled={true}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
export default Details;
