import React, { useState, useEffect } from 'react';
import { MdDownload, MdOutlineRefresh } from 'react-icons/md';
import BBITable from '../../../helpers/bBITable/BBITable';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewDriver from '../../../features/views/users/driver/Driver';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import Input from '../../../assets/essentials/Input';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import PayrollPaymentDetail from '../../../features/views/payroll/AppliedPayments/PayrollAppliedPayments';
import { GetPreviousWeekOfYear } from '../../../helpers/search/HelperFunctions';

function Paylocity({ toggleBoardRefresh }: { toggleBoardRefresh: boolean }) {
  const filterValue = useOutletContext();
  const [paylocityBoard, setPaylocityBoard] = useState<
    PayrollPaylocityBoardItem[]
  >([]);
  const [driverOptions, setDriverOptions] = useState<DriverOption[]>([]);
  const [searchParams, setSearchParams] = useSearchParams({
    weekOfYear: GetPreviousWeekOfYear(),
    year: new Date().getFullYear().toString(),
  });

  useEffect(() => {
    Get(
      `/Accounting/GetDriverPaylocityPayrollSummary?${searchParams.toString()}`,
    ).then(response => {
      if (response) {
        setPaylocityBoard(response.data);
      }
    });
    if (driverOptions.length === 0) {
      Get(`/Employee/GetDriverOptions/`).then(response => {
        if (response) {
          setDriverOptions(response.data);
        }
      });
    }
  }, [searchParams, driverOptions.length, toggleBoardRefresh]);

  const HandleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const newSearchParams = new URLSearchParams();
    formData.forEach((value, name) => {
      if (value !== '') {
        newSearchParams.set(name, value.toString());
      }
    });

    //no need to call our GET again because of searchParams in useEffect dependency array
    setSearchParams(newSearchParams);
  };

  function DownloadReportCSV() {
    Get(
      `/Accounting/DownloadDriverPaylocityReportCSV?${searchParams.toString()}`,
    ).then(response => {
      if (response) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =
          new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
          '_Driver Payroll Report.csv';
        a.click();
        URL.revokeObjectURL(url);
      }
    });
  }

  function FormatPayrolls(payrolls) {
    return payrolls.map(payroll => ({
      ...payroll,
      amount: payroll.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      hours: payroll.hours.toLocaleString(),
    }));
  }

  const tableData = FormatPayrolls(FilterData(paylocityBoard, filterValue));

  const columnConfig = [
    {
      key: '1',
      attribute: 'driverName',
      attributeprimaryhidden: 'employeeId',
      header: 'Driver',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'cashFlowId',
      header: 'Payment #',
      dataType: 'number',
      onClick: e =>
        View(<PayrollPaymentDetail cashFlowId={e.target.innerText} />),
    },
    {
      key: '3',
      attribute: 'weekOfYear',
      header: 'Week #',
      dataType: 'number',
    },
    {
      key: '4',
      attribute: 'employeePayrollId',
      header: 'Employee Payroll ID',
    },
    {
      key: '5',
      attribute: 'det',
      header: 'DET',
    },
    {
      key: '6',
      attribute: 'detCode',
      header: 'DET Code',
    },
    {
      key: '7',
      attribute: 'hours',
      header: 'Hours',
      dataType: 'formattedNumber',
    },
    {
      key: '8',
      attribute: 'amount',
      header: 'Amount',
      dataType: 'currency',
    },
  ];

  const PaylocityTable = ({ data, columnConfig }) => (
    <BBITable data={data} columns={columnConfig} />
  );

  const SearchForm = () => {
    return (
      <form onSubmit={HandleSubmit}>
        <label htmlFor="driverId">
          <h3>Driver</h3>
        </label>
        <Select defaultValue={searchParams.get('driverId')} name="driverId">
          <option value="">All Drivers</option>
          <OptionList
            optionData={driverOptions}
            attributeID="driverId"
            attributeName="name"
          />
        </Select>
        <label htmlFor="weekOfYear">
          <h3>Week</h3>
        </label>
        <Input
          defaultValue={searchParams.get('weekOfYear')}
          type="number"
          name="weekOfYear"
          id="weekOfYear"
          max={52}
          min={1}
          step={1}
          required
        />
        <label htmlFor="year">
          <h3>Year</h3>
        </label>
        <Input
          defaultValue={searchParams.get('year')}
          type="number"
          name="year"
          id="year"
          min={2023}
          required
        />
        <button type="submit">
          <MdOutlineRefresh />
          Search
        </button>
        <button onClick={DownloadReportCSV}>
          <MdDownload />
          Download
        </button>
      </form>
    );
  };

  const NoResultsSection = () => {
    const selectedDriverName = driverOptions.find(
      driver => driver.driverId === Number(searchParams.get('driverId')),
    )?.name;

    return (
      <section>
        No results for Week {searchParams.get('weekOfYear')} of{' '}
        {searchParams.get('year')}
        {selectedDriverName ? ` and Driver ${selectedDriverName}` : ''}
        {filterValue ? ` and Filter ${filterValue}` : ''}
      </section>
    );
  };

  return (
    <>
      <Header>{driverOptions.length !== 0 && <SearchForm />}</Header>
      {tableData.length > 0 ? (
        <PaylocityTable data={tableData} columnConfig={columnConfig} />
      ) : (
        <NoResultsSection />
      )}
    </>
  );
}
export default Paylocity;
