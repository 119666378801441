import React, { ReactNode, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import classes from './Accordion.module.css';

/**
 * Accordion component that can be expanded or collapsed.
 *
 * @component
 * @example
 * ```jsx
 * <Accordion
 *   title="Section 1"
 *   content={<p>This is the content of section 1.</p>}
 *   defaultActive={data.length > 0}
 * />
 * ```
 */

type AccordionProps = {
  title: ReactNode | string;
  content: ReactNode;
  defaultActive: boolean;
};

const Accordion = ({
  title,
  content,
  defaultActive = false,
}: AccordionProps) => {
  const [isActive, setIsActive] = useState(defaultActive);

  return (
    <div className={`${classes.item} ${isActive ? classes.active : ''}`}>
      <span
        className={classes.title}
        onClick={() => setIsActive(prev => !prev)}
      >
        <span className={`${classes.icon} ${isActive ? classes.isActive : ''}`}>
          <BsChevronDown />
        </span>
        <span>{title}</span>
      </span>
      <div className={classes.content}>{content}</div>
    </div>
  );
};

export default Accordion;
