import React, { useState } from 'react';
import classes from '../../../../assets/Global.module.css';
import Button from '../../../../assets/essentials/Button';
import PostBody from '../../../../api/internal/PostBody';
import Close from '../../../../helpers/slab/Close';
import ReversePaymentModal from '../../receivable/AppliedPayments/ReversePaymentModal';

const Admin = ({ cashFlowId }: { cashFlowId: Number }) => {
  const [
    isReversePaymentModalOpen,
    setIsReversePaymentModalOpen,
  ] = useState(false);

  function ReversePayrollPayment() {
    const params = {
      cashFlowId: cashFlowId,
    };
    PostBody(`/Accounting/ReversePayrollPayment`, params).then(response => {
      if (response) {
        Close();
      }
    });
  }

  return (
    <div className={classes.marginTop05Rem}>
      {isReversePaymentModalOpen && (
        <ReversePaymentModal
          closeModal={() => setIsReversePaymentModalOpen(false)}
          handleReverse={() => ReversePayrollPayment()}
        />
      )}
      <Button
        variant="good"
        onClick={() => setIsReversePaymentModalOpen(true)}
      >
        Reverse Payroll Payment
      </Button>
    </div>
  );
};

export default Admin;
